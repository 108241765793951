import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import ReportScreenSingle from "../../mycomponents/CRUD/ReportScreenSingle";
import ListingScreen from "../../mycomponents/CRUD/ListingScreen";
import { CustomDatePicker } from "../../mycomponents/DynamicForm";
import moment from "moment";
import { api } from "../../services/api";

export const couponCodefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

  const LedgerAccountComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
    const coupon_id = useForm((state) => state.formObject['_id']);
    const coupon_name = useForm((state) => state.formObject['name']);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [month, setMonth] = React.useState(new Date());
    const [total, setTotal] = React.useState(0);

    const generateTotal = async () => {
      let bodyObj = {
        page: 1,
        limit: 15000,
        sortColumn: "date",
        sortDirection: "desc",
        tabFilter: {
          coupon_code_id: coupon_id,
          date: {
            $gte: moment(month).startOf('month').toDate(),
            $lt: moment(month).endOf('month').toDate(),
          }
        },
        search: '',
        groupBy: null
      };

      const responseData = await api.get("Invoices", bodyObj);

      console.log(responseData);

      if (responseData && responseData.data && responseData.data.data) {
        let total = 0;
        responseData.data.data.forEach((item) => {
          total += item.total;
        });
        setTotal(total);
      }
    }

    useEffect(() => {
      generateTotal();
    }, [month, coupon_id]);

    return <View flex>
      {/* <ReportScreenSingle
        moduleName={"CouponCodeUsage"}
        hideWrapper={true}
        noMono={false}
        hideHeader={false}
        params={{
          quickFilterKey: 'coupon_code_id',
          quickFilterValue: coupon_id,
          hideQuickFilter: true,
        }}
        route={{
          params: {
            quickFilterKey: 'coupon_code_id',
            quickFilterValue: coupon_id,
            hideQuickFilter: true,
            name: "CouponCodeUsage",
          }
        }}
      /> */}

      <CustomDatePicker
        pickerType={"month"}
        value={month}
        onChange={(date) => {
          console.log(date);
          setMonth(date);
        }}
        label={"Month"}
        placeholder={"Select Month"}
      />

      <Text h2 marginT-10>Total Sale using {coupon_name}: {total}</Text>

      <ListingScreen
        key={month}
        {...{
          moduleName: 'Invoices',
          hideWrapper: true,
          hideMainHeader: true,
          extraFilters: {
            coupon_code_id: coupon_id,
            date: {
              $gte: moment(month).startOf('month').toDate(),
              $lt: moment(month).endOf('month').toDate(),
            }
          },
        }}
      />

    </View>
  });


  const formFields = [
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      placeholder: 'Enter the name',
      visible: true,
      width: '25%',
      tab: "General",
    },
    {
      label: 'Discount Type',
      key: 'discount_type',
      type: 'select',
      placeholder: 'Enter the discount type',
      visible: true,
      width: '25%',
      options: [
        { value: 'percentage', label: 'Percentage' },
        { value: 'amount', label: 'Amount' },
      ],
      onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        setObj({
          updateVisiblity: Math.random(),
        })
      },
      tab: "General",
    },
    {
      label: 'Discount Percentage',
      key: 'discount_percentage',
      type: 'number',
      placeholder: 'Enter the discount percentage',
      visible: (connectedObject) => (connectedObject.discount_type == 'percentage'),
      width: '25%',
      tab: "General",
    },
    {
      label: 'Discount Amount',
      key: 'discount_amount',
      type: 'number',
      placeholder: 'Enter the discount amount',
      visible: (connectedObject) => (connectedObject.discount_type === 'amount'),
      width: '25%',
      tab: "General",
    },
    {
      label: 'Max Discount',
      key: 'max_discount',
      type: 'number',
      placeholder: 'Enter the max discount',
      visible: (connectedObject) => (connectedObject.discount_type === 'percentage'),
      width: '25%',
      tab: "General",
    },
    {
      label: 'Min Order Amount',
      key: 'min_order_amount',
      type: 'number',
      placeholder: 'Enter the min order amount',
      visible: true,
      width: '25%',
      tab: "General",
    },
    {
      label: '',
      type: 'custom',
      placeholder: 'Enter the Ledger Account',
      visible: (connectedObject) => {
        if (view) {
          return true;
        } else {
          return false;
        }
      },
      width: '100%',
      tab: 'Usage History',
      component: LedgerAccountComponent
    },
  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%',
        viewField: true,
      });
    });
  }

  return formFields;
}

export const couponCodeMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const couponCodeMain = {
  fields: couponCodefields,
  mobileCard: null
}
