// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, RefreshControl } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, Avatar, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, Heading, Row, SecondaryButton, CustomTextField, Label, CustomCheckbox } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"
import dayjs from "dayjs"
import { Camera, CameraType } from 'expo-camera';
import Svg, { G, Path } from "react-native-svg"
import Header from "./Header"
import Footer from "./Footer"
import { Icon } from "react-native-eva-icons"
import { App } from "antd"
import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { ProductCard } from "./Home"
import RenderHTML from "react-native-render-html"

const isDesktop = Dimensions.get('window').width >= 768;

const ContactUs = (props) => {
    const { message, modal } = App.useApp();
    const [product, setProduct] = useState({});
    const [currentImage, setCurrentImage] = useState(0);
    const cart = GlobalMasterStore(state => state.cart);
    const setCart = GlobalMasterStore(state => state.setCart);

    const [relatedProducts, setRelatedProducts] = useState([]);

    const wishList = GlobalMasterStore(state => state.wishList);
    const setWishList = GlobalMasterStore(state => state.setWishList);

    const params = useParams();

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    const title = "Contact us";
    const content = `<p>You may contact us using the information below:</p>
    <p>Merchant Legal entity name: Ikaki<br />Registered Address: Ikaki, E-4/90, Arera Colony, 10 No. Market Bhopal MADHYA PRADESH 462016<br />Operational Address: Ikaki, E-4/90, Arera Colony, 10 No. Market Bhopal MADHYA PRADESH 462016<br />Telephone No:<br />E-Mail ID: houseofikaki@gmail.com</p>`;

    return (<>
        <ScrollView style={{
            height: Dimensions.get("window").height,
        }}>
            <Card>
                <View style={{
                    position: 'relative',
                }}>
                    <Header theme={"white_bg"} />
                    <View style={[{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        overflow: "hidden",
                        height: 70,
                        minHeight: 70,
                        borderWidth: 1,
                        borderColor: "#e5e7eb",
                    }]}>
                    </View>
                </View>
                <Row>
                    <View style={{
                        width: '100%',
                        padding: isDesktop ? 50 : 20,
                    }}>
                        <View row={isDesktop} style={{
                            width: '100%'
                        }}>
                            <Col width={isDesktop ? "100%" : '100%'}
                                style={{
                                    paddingLeft: isDesktop ? 40 : 0,
                                }}>
                                <View style={{
                                    paddingBottom: 5,
                                }}>
                                    <Text style={{
                                        color: "#000",
                                        fontSize: 24,
                                        fontFamily: 'SourceSansProBold'
                                    }}>{title}</Text>
                                </View>


                                <View style={{
                                    paddingBottom: 15,
                                }}>
                                    {Platform.OS == 'web' ? <div dangerouslySetInnerHTML={{ __html: content }} /> : <RenderHTML contentWidth={Dimensions.get('window').width - 40} source={{ html: content }} />}
                                </View>
                            </Col>
                        </View>
                    </View>
                </Row>

                <Footer />
            </Card >
        </ScrollView >
    </>);
};

export default ContactUs;