// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, RefreshControl } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, Avatar, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, Heading, Row, SecondaryButton, CustomTextField, Label, CustomCheckbox } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"
import dayjs from "dayjs"
import { Camera, CameraType } from 'expo-camera';
import Svg, { G, Path } from "react-native-svg"
import Footer from "./Footer"
import Header from "./Header"
import { FlashList } from "@shopify/flash-list"
import { ProductCard } from "./Home"

const isDesktop = Dimensions.get('window').width >= 768;

const filterIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={800}
        height={800}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <Path
            fill="#000"
            fillRule="evenodd"
            d="M15 10.5A3.502 3.502 0 0 0 18.355 8H21a1 1 0 1 0 0-2h-2.645a3.502 3.502 0 0 0-6.71 0H3a1 1 0 0 0 0 2h8.645A3.502 3.502 0 0 0 15 10.5zM3 16a1 1 0 1 0 0 2h2.145a3.502 3.502 0 0 0 6.71 0H21a1 1 0 1 0 0-2h-9.145a3.502 3.502 0 0 0-6.71 0H3z"
            clipRule="evenodd"
        />
    </Svg>
)

const deleteIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={800}
        height={800}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <Path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M10 12v5M14 12v5M4 7h16M6 10v8a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3v-8M9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2H9V5Z"
        />
    </Svg>
)

const HamIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={800}
        height={800}
        viewBox="0 0 24 24"
        {...props}
    >
        <G fill="none" fillRule="evenodd">
            <Path d="M0 0h24v24H0z" />
            <Path
                stroke="#0C0310"
                strokeLinecap="round"
                strokeWidth={2}
                d="M5 7h14M5 17h14M5 12h14"
            />
        </G>
    </Svg>
)

const plusIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={800}
        height={800}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <Path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 12h16m-8-8v16"
        />
    </Svg>
)

const minusIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={800}
        height={800}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <Path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 12h12"
        />
    </Svg>
)

const Shop = (props) => {
    const [search, setSearch] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [sizes, setSizes] = useState([]);
    const [colors, setColors] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [expandedOptions, setExpandedOptions] = useState(["categories", "price", "size", "color", "material"]);

    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [ItemAttributes, setItemAttributes] = useState([]);
    const [meta, setMeta] = useState({});
    const [categoryName, setCategoryName] = useState("");

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    const getProducts = async () => {
        let queryParams = {};

        if (Platform.OS === 'web') {
            const params = window.location.search.substring(1).split('&')
            params.map((param) => {
                const [key, value] = param.split('=')
                if (key) {
                    queryParams[key] = value;
                }
            })
        } else {
            queryParams = props.route.params || {};
        }

        let { category_name } = queryParams;

        if (category_name) {
            category_name = category_name.replace("%20", " ");
        }

        const response = await api.customRoute("shop/getProducts", {
            search,
            minPrice,
            maxPrice,
            page: currentPage,
            perPage,
            meta,
            category_name,
        });

        setCategoryName(category_name);
        setProducts(response.data.data);
        setTotal(response.data.total);
        setCurrentPage(response.data.currentPage);
        setPerPage(response.data.perPage);
        setHasNextPage(response.data.hasNextPage);
        setCategoriesOptions(response.data.ItemCategories);
        setItemAttributes(response.data.ItemAttributes);
    };

    useEffect(() => {
        getProducts();
    }, [search, minPrice, maxPrice, meta, window?.location?.href, props?.route?.params]);

    return (<>
        <ScrollView style={{
            height: Dimensions.get("window").height,
        }}>
            <Card>
                <View style={{
                    position: 'relative',
                }}>
                    <Header />
                    <View style={[{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        borderRadius: 8,
                        overflow: "hidden",
                        height: 400,
                        minHeight: 400,
                    }]}>
                        <Image style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                        }} source={{
                            uri: "https://pub-ed4dcf9e4976449fa869f76b2ec53b5d.r2.dev/2023/December/Items/755000925145414500000.jpg",
                        }} />
                        <View style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "black",
                            opacity: 0.5,
                        }}>
                        </View>
                        <View center>
                            <Text style={{
                                color: "#fff",
                                fontSize: 40,
                                fontWeight: "bold",
                                fontFamily: "SourceSansProSemiBold",
                                letterSpacing: 0.6
                            }}>
                                {categoryName || "Shop"}
                            </Text>

                            <View marginT-15 style={{
                                maxWidth: isDesktop ? "60%" : "90%",
                            }}>
                                <Text style={{
                                    color: "#fff",
                                    fontSize: 14,
                                    fontFamily: "SourceSansProRegular",
                                    letterSpacing: 0.6,
                                    lineHeight: 26,
                                    textAlign: "center",
                                }}>
                                    Ikaki is situated in Bhopal, the culturally rich birthplace of its founder. Inspired by the city's handicrafts and heritage, the brand set out to reimagine heirlooms for modern tastes. With an emphasis on quality karigari tailored for each customer, Ikaki connects India's rich history with accessible, fashion-forward pieces. The result bridges traditional techniques and contemporary style.
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View row spread marginT-20>
                    {isDesktop && <Row style={{ width: 300 }}>
                        <Col>
                            <View paddingL-25>
                                <View style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    paddingBottom: 15,
                                    borderBottom: '1px solid #DCDCDC',
                                }}>
                                    <View>
                                        <View style={{
                                            marginRight: 10,
                                        }}>
                                            {filterIcon({ width: 20, height: 20, fill: "black" })}
                                        </View>
                                    </View>
                                    <View>
                                        <Text style={{
                                            color: '#000',
                                            fontFamily: 'SourceSansProSemiBold',
                                            marginRight: 10,
                                        }}>FILTER BY</Text>
                                    </View>
                                </View>

                                <View style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingBottom: 15,
                                    paddingTop: 15,
                                    width: 240
                                }}>
                                    <View style={{
                                        flexDirection: 'row',
                                    }}>
                                        <View style={{
                                            marginRight: 15,
                                        }}>
                                            {HamIcon({ width: 16, height: 16, fill: "black" })}
                                        </View>
                                        <View>
                                            <Text style={{
                                                color: '#000',
                                                fontFamily: 'SourceSansProSemiBold',

                                            }}>PRICE</Text>
                                        </View>
                                    </View>

                                    <View>
                                        {expandedOptions.includes("price") && <TouchableOpacity onPress={() => {
                                            setExpandedOptions(expandedOptions.filter((el) => el != "price"));
                                        }}
                                        >
                                            {minusIcon({ width: 18, height: 18, fill: "black" })}
                                        </TouchableOpacity>}
                                        {!expandedOptions.includes("price") && <TouchableOpacity onPress={() => {
                                            setExpandedOptions([...expandedOptions, "price"]);
                                        }}
                                        >
                                            {plusIcon({ width: 18, height: 18, fill: "black" })}
                                        </TouchableOpacity>}
                                    </View>
                                </View>

                                {expandedOptions.includes("price") &&
                                    <View style={{
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        paddingBottom: 15,
                                        paddingTop: 15,
                                    }}>
                                        <View style={{
                                            marginRight: 10,
                                            width: 100,
                                        }}>
                                            <CustomNumberInput
                                                placeholder={"Min"}
                                                value={minPrice}
                                                onChange={(val) => {
                                                    setMinPrice(val);
                                                }}
                                            />
                                        </View>
                                        <View style={{
                                            marginRight: 10,
                                        }}>
                                            <Text style={{
                                                fontFamily: 'SourceSansProSemiBold',
                                                color: '#000',
                                                fontSize: 16,
                                            }}>-</Text>
                                        </View>
                                        <View style={{
                                            width: 100,
                                        }}>
                                            <CustomNumberInput
                                                value={maxPrice}
                                                placeholder={"Max"}
                                                onChange={(val) => {
                                                    setMaxPrice(val);
                                                }}
                                            />
                                        </View>
                                    </View>}

                                {ItemAttributes && ItemAttributes.map((attribute) => <View>
                                    <View style={{
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        paddingBottom: 15,
                                        paddingTop: 15,

                                    }}>
                                        <View style={{
                                            flexDirection: 'row',
                                        }}>
                                            <View style={{
                                                marginRight: 15,
                                            }}>
                                                {HamIcon({ width: 16, height: 16, fill: "black" })}
                                            </View>
                                            <View>
                                                <Text style={{
                                                    color: '#000',
                                                    fontFamily: 'SourceSansProSemiBold',

                                                }}>
                                                    {attribute.name}
                                                </Text>
                                            </View>
                                        </View>

                                        <View>
                                            {expandedOptions.includes(attribute.name) && <TouchableOpacity onPress={() => {
                                                setExpandedOptions(expandedOptions.filter((el) => el != attribute.name));
                                            }}
                                            >
                                                {minusIcon({ width: 18, height: 18, fill: "black" })}
                                            </TouchableOpacity>}
                                            {!expandedOptions.includes(attribute.name) && <TouchableOpacity onPress={() => {
                                                setExpandedOptions([...expandedOptions, attribute.name]);
                                            }}
                                            >
                                                {plusIcon({ width: 18, height: 18, fill: "black" })}
                                            </TouchableOpacity>}
                                        </View>
                                    </View>

                                    {/* sizes checkbox---------- */}

                                    {(expandedOptions.includes(attribute.name) && attribute.options.length > 0) && <View style={{
                                        justifyContent: 'flex-start',
                                        paddingBottom: 15,
                                    }}>

                                        {attribute.options.map((option, i) => <View key={i} style={{
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                        }}>
                                            <View>
                                                <CustomCheckbox
                                                    value={meta[attribute.name]?.includes(option) || false}
                                                    onChange={(val) => {
                                                        if (val) {
                                                            setMeta({
                                                                ...meta,
                                                                [attribute.name]: [...(meta[attribute.name] || []), option]
                                                            });
                                                        } else {
                                                            setMeta({
                                                                ...meta,
                                                                [attribute.name]: meta[attribute.name].filter((el) => el != option)
                                                            });
                                                        }
                                                    }}
                                                />
                                            </View>
                                            <View>
                                                <Text style={{
                                                    color: '#000',
                                                    fontSize: 14,
                                                }}>
                                                    {option}
                                                </Text>
                                            </View>
                                        </View>
                                        )}
                                    </View>}
                                </View>)}

                            </View>
                        </Col>
                    </Row>}

                    <View style={{
                        flexWrap: 'wrap',
                        width: Dimensions.get("window").width - 300,
                        minHeight: Dimensions.get("window").height - 100,
                    }}>
                        <View style={{
                            height: 40,
                            marginBottom: 20,
                            marginRight: 20
                        }}>
                            <CustomTextField value={search} onChange={(value) => {
                                setSearch(value)
                            }} placeholder="Search..." />
                        </View>

                        <FlashList
                            data={products}
                            numColumns={Platform.OS == 'web' ? 4 : 2}
                            estimatedItemSize={170}
                            renderItem={({ item }) => (
                                <ProductCard item={item} navigationFn={navigationFn} />
                            )}
                            keyExtractor={(item) => item._id}
                        />
                        {/* {products.map((item, key) => (
                            <ProductCard key={key} item={item} navigationFn={navigationFn} />
                        ))} */}
                    </View>
                </View>
                <Footer />
            </Card>
        </ScrollView>
    </>);
};

export default Shop;