import React, { useEffect } from "react";
import { View, Card, Text, TouchableOpacity, Image } from "react-native-ui-lib";
import { addButton } from "../hrms/shiftModel";
import { Dimensions, Platform } from "react-native";
import { Icon } from "react-native-eva-icons";
import { CustomCheckbox, CustomDropDown, CustomEditorField, CustomNumberInput, CustomSelect, CustomTextField, CustomUpload, Label, SecondaryButton } from "../../mycomponents/DynamicForm";
import DynamicTable from "../../mycomponents/DynamicTable";

const getCombinations = (arrays) => {
    const results = [];

    if (arrays.length === 1) {
        return arrays[0].map(value => [value]);
    }

    const [first, ...rest] = arrays;

    first.forEach(value1 => {
        getCombinations(rest).forEach(combination => {
            results.push([value1, ...combination]);
        });
    });

    return results;
}

const getVariantMetas = (options) => {
    if (options.length == 0) {
        return [];
    }

    const variants = [];
    const valueSets = options.map(o => o.value);
    const combinations = getCombinations(valueSets);

    combinations.forEach(combo => {
        const meta = {};
        combo.forEach((value, index) => {
            meta[options[index].name] = [value];
        });
        variants.push({ meta });
    });

    return variants;
}

export const itemfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const ProductOptions = ({ field, useForm, viewOnly }) => {
        const options = useForm((state) => state.formObject?.['options']) || [];
        const setObj = useForm((state) => state.setFormObject);
        const getObj = useForm((state) => state.getFormObject);

        useEffect(() => {
            // const variant_products = getObj().variant_products || [];
            // const name = getObj().name;
            // const sku = getObj().sku;
            // const sales_rate = getObj().sales_rate;

            // const newVariants = [];

            // let variantMetaCombos = getVariantMetas(options);

            // for (const variantMetaCombo of variantMetaCombos) {
            //     const values = Object.values(variantMetaCombo.meta);

            //     const variant = {
            //         meta: variantMetaCombo.meta,
            //         sku: sku + "-" + values.join("-"),
            //         name: name,
            //         sales_rate: sales_rate,
            //     };

            //     const existingVariant = variant_products.find(v => JSON.stringify(v.meta) == JSON.stringify(variant.meta));
            //     if (existingVariant) {
            //         newVariants.push(existingVariant);
            //     } else {
            //         newVariants.push(variant);
            //     }
            // }

            // setObj({
            //     variant_products: newVariants,
            //     updateVisiblity: Math.random()
            // })
        }, [options]);

        return <View style={{
            width: '50%'
        }}>
            <View row spread style={{
                width: '100%'
            }}>
                <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                    width: Platform.OS == 'web' ? '49%' : '100%'
                }}>
                    {Label({ label: 'Name' })}
                </View>
                <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                    width: Platform.OS == 'web' ? '49%' : '100%'
                }}>
                    {Label({ label: 'Values' })}
                </View>
            </View>

            {options.map((option, i) => {
                return <View key={i} row spread marginB-10 style={{
                    width: '100%'
                }}>
                    <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                        width: Platform.OS == 'web' ? '49%' : '100%'
                    }}>
                        <CustomSelect
                            value={option.name}
                            options={(response.ItemAttributes || []).map((el) => ({ label: el.name, value: el.name })) || []}
                            onChange={(value) => {
                                const newOptions = [...options];
                                newOptions[i].name = value;
                                setObj({ options: newOptions });
                            }}
                            creatableAction={(props) => {
                                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
                                OpenModalCustom({ newModule: "ItemAttributes", field_key: null, params })
                            }}
                        />
                    </View>
                    <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                        width: Platform.OS == 'web' ? '49%' : '100%'
                    }}>
                        <CustomSelect
                            value={option.value}
                            options={((response.ItemAttributes || []).find((el) => el.name == option.name)?.options || []).map((el) => ({ label: el, value: el })) || []}
                            onChange={(value) => {
                                const newOptions = [...options];
                                newOptions[i].value = value;
                                setObj({ options: newOptions });
                            }}
                            multiple={true}
                        />
                    </View>
                    <View marginT-10>
                        <TouchableOpacity onPress={() => {
                            const newOptions = [...options];
                            newOptions.splice(i, 1);
                            setObj({ options: newOptions });

                            // const variant_products = getObj().variant_products || [];
                            // const name = getObj().name;
                            // const sku = getObj().sku;
                            // const sales_rate = getObj().sales_rate;

                            // const newVariants = [];

                            // let variantMetaCombos = getVariantMetas(newOptions);

                            // for (const variantMetaCombo of variantMetaCombos) {
                            //     const values = Object.values(variantMetaCombo.meta);

                            //     const variant = {
                            //         meta: variantMetaCombo.meta,
                            //         sku: sku + "-" + values.join("-"),
                            //         name: name,
                            //         sales_rate: sales_rate,
                            //     };

                            //     const existingVariant = variant_products.find(v => JSON.stringify(v.meta) == JSON.stringify(variant.meta));
                            //     if (existingVariant) {
                            //         newVariants.push(existingVariant);
                            //     } else {
                            //         newVariants.push(variant);
                            //     }
                            // }

                            // setObj({
                            //     variant_products: newVariants,
                            //     updateVisiblity: Math.random()
                            // })
                        }}>
                            <Icon name="trash-2-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                        </TouchableOpacity>
                    </View>
                </View>
            })}

            <View marginT-10 left>
                <SecondaryButton label={"Add Option"} onPress={() => {
                    const newOptions = [...options];
                    newOptions.push({ name: '', value: [] });
                    setObj({ options: newOptions });
                }}>
                </SecondaryButton>
            </View>
        </View>
    }

    // const variantProductColumns = [
    //     {
    //         "field": "name",
    //         "accessor": "name",
    //         "label": "Name",
    //         "Header": "Name",
    //         "editable": true,
    //         "type": "text",
    //         "visible": true,
    //         "order": 1
    //     },
    //     {
    //         "field": "sku",
    //         "accessor": "sku",
    //         "label": "SKU",
    //         "Header": "SKU",
    //         "editable": true,
    //         "type": "text",
    //         "visible": true,
    //         "order": 2
    //     },
    //     {
    //         "field": "sales_rate",
    //         "accessor": "sales_rate",
    //         "label": "Sales Rate",
    //         "Header": "Sales Rate",
    //         "editable": true,
    //         "type": "number",
    //         "visible": true,
    //         "order": 3
    //     }, {
    //         "field": "meta",
    //         "accessor": "meta",
    //         "label": "",
    //         "Header": "",
    //         "editable": true,
    //         "type": "custom",
    //         "visible": true,
    //         "order": 3,
    //         "component": ({ field, useForm, tKey, tIndex, viewOnly }) => {
    //             const meta = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['meta']) || {};

    //             return <View>
    //                 {Object.keys(meta).map((key, i) => {
    //                     return <View row spread key={i}>
    //                         <Text>{key}</Text>
    //                         {meta[key].map((value, j) => {
    //                             return <Text key={j}>{value}</Text>
    //                         })}

    //                     </View>
    //                 })}
    //             </View>
    //         }
    //     }
    // ];

    const batchNumbersColumns = [
        {
            "field": "batch_number",
            "label": "Batch Number",
            "editable": true,
            "type": "text",
            "visible": true,
            "order": 1
        },
        {
            "field": "expiry_date",
            "label": "Expiry Date",
            "editable": true,
            "type": "date",
            "visible": true,
            "order": 2
        }, {
            "field": "quantity",
            "label": "Quantity",
            "editable": true,
            "type": "number",
            "visible": true,
            "order": 3
        }
    ];

    batchNumbersColumns && batchNumbersColumns.map((column) => {
        column.Header = column.label;
        column.accessor = column.field;
    });

    const CustomAttributes = ({ field, useForm, viewOnly }) => {
        const meta = useForm((state) => state.formObject?.['meta']) || {};
        const setObj = useForm((state) => state.setFormObject);
        const getObj = useForm((state) => state.getFormObject);

        const options = useForm((state) => state.formObject?.['options']) || [];

        return <View style={{
            width: '50%'
        }}>
            <View row spread style={{
                width: '100%'
            }}>
                <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                    width: Platform.OS == 'web' ? '49%' : '100%'
                }}>
                    {Label({ label: 'Attribute' })}
                </View>
                <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                    width: Platform.OS == 'web' ? '49%' : '100%'
                }}>
                    {Label({ label: 'Values' })}
                </View>
                <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                    width: Platform.OS == 'web' ? '49%' : '100%'
                }}>
                    {Label({ label: 'Display as an option?' })}
                </View>
            </View>
            {Object.keys(meta).map((key, i) => {
                return <View key={i} row spread marginB-10 style={{
                    width: '100%'
                }}>
                    <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                        width: Platform.OS == 'web' ? '49%' : '100%'
                    }}>
                        <CustomSelect
                            value={key}
                            options={(response.ItemAttributes || []).map((el) => ({ ...el, label: el.name, value: el.name })) || []}
                            onChange={(value) => {
                                const newMeta = { ...meta };
                                newMeta[value] = newMeta[key];
                                delete newMeta[key];
                                setObj({ meta: newMeta });
                            }}
                            creatableAction={(props) => {
                                console.log(props);
                                const params = { ...(props._id ? { isEdit: true, id: props._id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
                                OpenModalCustom({ newModule: "ItemAttributes", field_key: null, params })
                            }}
                        />
                    </View>
                    <View marginR-5 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                        width: Platform.OS == 'web' ? '49%' : '100%'
                    }}>
                        <CustomSelect
                            value={meta[key]}
                            options={((response.ItemAttributes || []).find((el) => el.name == key)?.options || []).map((el) => ({ label: el, value: el }))}
                            onChange={(value) => {
                                const newMeta = { ...meta };
                                newMeta[key] = value;
                                setObj({ meta: newMeta });

                                if (options.find((el) => el.name == key)) {
                                    const newOptions = [...options];

                                    const index = newOptions.findIndex((el) => el.name == key);
                                    if (index == -1) {
                                        newOptions.push({ name: key, value: newMeta[key] });
                                    } else {
                                        newOptions[index].value = newMeta[key];
                                    }

                                    const variant_products = getObj().variant_products || [];
                                    const name = getObj().name;
                                    const sku = getObj().sku;
                                    const sales_rate = getObj().sales_rate;
                                    const mrp_rate = getObj().mrp_rate;
                                    const height = getObj().height;
                                    const length = getObj().length;
                                    const breadth = getObj().breadth;
                                    const weight = getObj().weight;
                                    const description = getObj().description;

                                    const newVariants = [];

                                    let variantMetaCombos = getVariantMetas(newOptions);

                                    for (const variantMetaCombo of variantMetaCombos) {
                                        const values = Object.values(variantMetaCombo.meta);

                                        const variant = {
                                            meta: variantMetaCombo.meta,
                                            sku: sku + "-" + values.join("-"),
                                            name: name,
                                            sales_rate: sales_rate,
                                            mrp_rate: mrp_rate,
                                            height: height,
                                            length: length,
                                            breadth: breadth,
                                            weight: weight,
                                            description: description
                                        };

                                        const existingVariant = variant_products.find(v => JSON.stringify(v.meta) == JSON.stringify(variant.meta));

                                        if (existingVariant) {
                                            newVariants.push(existingVariant);
                                        } else {
                                            newVariants.push(variant);
                                        }
                                    }

                                    setObj({
                                        options: newOptions,
                                        variant_products: newVariants,
                                        updateVisiblity: Math.random()
                                    })
                                }
                            }}
                            multiple={true}
                        />
                    </View>
                    <View marginR-5 marginT-10 flex={Platform.OS == 'web' ? '49%' : '100%'} style={{
                        width: Platform.OS == 'web' ? '49%' : '100%'
                    }}>
                        <CustomCheckbox
                            value={options.find((el) => el.name == key) ? true : false}
                            onChange={(value) => {
                                const newOptions = [...options];
                                console.log(value);
                                if (value == true) {
                                    const newMeta = { ...meta };
                                    const index = newOptions.findIndex((el) => el.name == key);
                                    if (index == -1) {
                                        newOptions.push({ name: key, value: newMeta[key] });
                                    } else {
                                        newOptions[index].value = newMeta[key];
                                    }

                                    console.log(newOptions);

                                    const variant_products = getObj().variant_products || [];
                                    const name = getObj().name;
                                    const sku = getObj().sku;
                                    const sales_rate = getObj().sales_rate;

                                    const newVariants = [];

                                    let variantMetaCombos = getVariantMetas(newOptions);

                                    console.log(variantMetaCombos);

                                    for (const variantMetaCombo of variantMetaCombos) {
                                        const values = Object.values(variantMetaCombo.meta);

                                        const variant = {
                                            meta: variantMetaCombo.meta,
                                            sku: sku + "-" + values.join("-"),
                                            name: name,
                                            sales_rate: sales_rate,
                                        };

                                        const existingVariant = variant_products.find(v => JSON.stringify(v.meta) == JSON.stringify(variant.meta));
                                        if (existingVariant) {
                                            newVariants.push(existingVariant);
                                        } else {
                                            newVariants.push(variant);
                                        }
                                    }

                                    console.log(newVariants);

                                    setObj({
                                        variant_products: newVariants,
                                        updateVisiblity: Math.random()
                                    })
                                } else {
                                    const index = newOptions.findIndex((el) => el.name == key);
                                    newOptions.splice(index, 1);

                                    const variant_products = getObj().variant_products || [];
                                    const name = getObj().name;
                                    const sku = getObj().sku;
                                    const sales_rate = getObj().sales_rate;

                                    const newVariants = [];

                                    let variantMetaCombos = getVariantMetas(newOptions);

                                    for (const variantMetaCombo of variantMetaCombos) {
                                        const values = Object.values(variantMetaCombo.meta);

                                        const variant = {
                                            meta: variantMetaCombo.meta,
                                            sku: sku + "-" + values.join("-"),
                                            name: name,
                                            sales_rate: sales_rate,
                                        };

                                        const existingVariant = variant_products.find(v => JSON.stringify(v.meta) == JSON.stringify(variant.meta));
                                        if (existingVariant) {
                                            newVariants.push(existingVariant);
                                        } else {
                                            newVariants.push(variant);
                                        }
                                    }

                                    setObj({
                                        variant_products: newVariants,
                                        updateVisiblity: Math.random()
                                    })
                                }
                                setObj({ options: newOptions, updateVisiblity: Math.random() });
                            }}
                        />
                    </View>

                    <View marginT-10>
                        <TouchableOpacity onPress={() => {
                            const newOptions = [...options];
                            const index = newOptions.findIndex((el) => el.name == key);

                            if (options.find((el) => el.name == key)) {
                                newOptions.splice(index, 1);
                            }

                            const variant_products = getObj().variant_products || [];
                            const name = getObj().name;
                            const sku = getObj().sku;
                            const sales_rate = getObj().sales_rate;

                            const newVariants = [];

                            let variantMetaCombos = getVariantMetas(newOptions);

                            for (const variantMetaCombo of variantMetaCombos) {
                                const values = Object.values(variantMetaCombo.meta);

                                const variant = {
                                    meta: variantMetaCombo.meta,
                                    sku: sku + "-" + values.join("-"),
                                    name: name,
                                    sales_rate: sales_rate,
                                };

                                const existingVariant = variant_products.find(v => JSON.stringify(v.meta) == JSON.stringify(variant.meta));
                                if (existingVariant) {
                                    newVariants.push(existingVariant);
                                } else {
                                    newVariants.push(variant);
                                }
                            }

                            const newMeta = { ...meta };
                            delete newMeta[key];
                            setObj({
                                variant_products: newVariants,
                                meta: newMeta,
                                updateVisiblity: Math.random()
                            })

                        }}>
                            <Icon name="trash-2-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                        </TouchableOpacity>
                    </View>
                </View>
            })}

            {/* <DynamicTable
                columns={[
                    {
                        "field": "attribute_id",
                        "label": "Attribute",
                        "editable": true,
                        "type": "select",
                        "visible": true,
                        "order": 1,
                        "Header": "Attribute",
                        "accessor": "attribute_id",
                        "placeholder": "Select the attribute",
                        "options": response.ItemAttributes || [],
                        creatableAction: (props) => {
                            const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
                            OpenModalCustom({ newModule: "ItemAttributes", field_key: null, params })
                        },
                    },
                    {
                        "field": "attribute_value",
                        "label": "Value",
                        "editable": true,
                        "type": "select",
                        "visible": true,
                        "order": 2,
                        "Header": "Value",
                        "accessor": "attribute_value",
                        "placeholder": "Select the value",
                        "options": [],
                    },
                ]}
                data={Object.keys(attributes).map((key) => {
                    return {
                        attribute_id: key,
                        attribute_value: attributes[key],
                    }
                })}
                actions={true}
                actionFunctions={(row) => ([{
                    label: 'Delete',
                    key: 'delete',
                    fn: ({ data, updateData, row, row_index }) => {
                        let newData = [...data];
                        newData.splice(row_index, 1);
                        updateData(newData);
                    }
                }])}
                editable={true}
            /> */}

            <View marginT-10 left>
                <SecondaryButton label={"Add Attribute"} onPress={() => {
                    const newMeta = { ...meta };
                    newMeta[''] = [];
                    setObj({ meta: newMeta });
                }}>
                </SecondaryButton>
            </View>
        </View>
    }


    const VariantProductsComponent = ({ field, useForm, viewOnly }) => {
        // in variantProductsComponent, we make the variant product editable by allowing user to update name,sku,sales rate and display the meta data
        // we need to display them in good cards format in 1/2 width, we can't use the table here

        const variant_products = useForm((state) => state.formObject?.['variant_products']) || [];
        const setObj = useForm((state) => state.setFormObject);
        const getObj = useForm((state) => state.getFormObject);

        return <View style={{
            width: '100%',
            flexDirection: 'row',
            flexWrap: 'wrap'
        }}>
            {variant_products.map((variant, i) => {
                return <Card key={i} style={{
                    width: '49%',
                    padding: 10,
                    marginBottom: 10,
                    marginRight: 10,
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: '#eeeeee'
                }}>

                    <View backgroundColor="#dcdcdc42" padding-10 row spread marginB-10 style={{
                        borderRadius: 4
                    }}>
                        <View row>
                            {Object.keys(variant.meta).map((key, i) => {
                                return <View key={i} marginT-5 row marginR-3>
                                    <Text marginR-3>{key}</Text>
                                    {variant.meta[key].map((value, j) => {
                                        return <Text key={j} marginR-3>{value}</Text>
                                    })}
                                </View>
                            })}
                        </View>
                        <View>
                            <Text>{"" + variant.quantity_in_hand}</Text>
                        </View>
                    </View>

                    <View row spread>
                        <View flex marginR-5>
                            <View row marginV-3>
                                {Label({ label: 'Name' })}
                                <TouchableOpacity style={{
                                    marginLeft: 10
                                }} onPress={() => {
                                    const newVariants = [...variant_products];
                                    newVariants[i].name = getObj().name;
                                    setObj({ variant_products: newVariants });
                                }}>
                                    <Icon name="refresh-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                                </TouchableOpacity>
                            </View>

                            <CustomTextField value={variant.name} onChange={(value) => {
                                const newVariants = [...variant_products];
                                newVariants[i].name = value;
                                setObj({ variant_products: newVariants });
                            }} />
                        </View>

                        <View flex marginL-5>
                            <View row marginV-3>
                                {Label({ label: 'SKU' })}
                            </View>
                            <CustomTextField value={variant.sku} onChange={(value) => {
                                const newVariants = [...variant_products];
                                newVariants[i].sku = value;
                                setObj({ variant_products: newVariants });
                            }} />
                        </View>
                    </View>

                    <View row spread>
                        <View flex marginR-5>
                            <View row marginV-3 marginT-8>
                                {Label({ label: 'Sales Rate' })}
                                <TouchableOpacity style={{
                                    marginLeft: 10
                                }} onPress={() => {
                                    const newVariants = [...variant_products];
                                    newVariants[i].sales_rate = getObj().sales_rate;
                                    setObj({ variant_products: newVariants });
                                }}>
                                    <Icon name="refresh-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                                </TouchableOpacity>
                            </View>

                            <CustomNumberInput value={variant.sales_rate} onChange={(value) => {
                                const newVariants = [...variant_products];
                                newVariants[i].sales_rate = value;
                                setObj({ variant_products: newVariants });
                            }} />

                        </View>

                        <View flex marginL-5>
                            <View row marginV-3 marginT-8>
                                {Label({ label: 'MRP Rate' })}
                                <TouchableOpacity style={{
                                    marginLeft: 10
                                }} onPress={() => {
                                    const newVariants = [...variant_products];
                                    newVariants[i].mrp_rate = getObj().mrp_rate;
                                    setObj({ variant_products: newVariants });
                                }}>
                                    <Icon name="refresh-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                                </TouchableOpacity>
                            </View>
                            <CustomNumberInput value={variant.mrp_rate} onChange={(value) => {
                                const newVariants = [...variant_products];
                                newVariants[i].mrp_rate = value;
                                setObj({ variant_products: newVariants });
                            }} />

                        </View>
                    </View>

                    <View>
                        <View row marginV-3 marginT-8>
                            {Label({ label: 'Images' })}
                            <TouchableOpacity style={{
                                marginLeft: 10
                            }} onPress={() => {
                                const newVariants = [...variant_products];
                                newVariants[i].image_urls = getObj().image_urls;
                                setObj({ variant_products: newVariants });
                            }}>
                                <Icon name="refresh-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                            </TouchableOpacity>
                        </View>

                        <CustomUpload
                            value={variant.image_urls}
                            onChange={(value) => {
                                const newVariants = [...variant_products];
                                newVariants[i].image_urls = value;
                                setObj({ variant_products: newVariants });
                            }}
                            multiple={true}
                            moduleName={moduleName}
                            mimeType={"image/*"}
                        />
                    </View>

                    {/* // "length": 10,
                    // "breadth": 15,
                    // "height": 20,
                    // "weight": 2.5 */}

                    <View row spread>
                        <View flex marginR-5>
                            <View row marginV-3 marginT-8>
                                {Label({ label: 'Length' })}
                                <TouchableOpacity style={{
                                    marginLeft: 10
                                }} onPress={() => {
                                    const newVariants = [...variant_products];
                                    newVariants[i].length = getObj().length;
                                    setObj({ variant_products: newVariants });
                                }}>
                                    <Icon name="refresh-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                                </TouchableOpacity>
                            </View>

                            <CustomNumberInput value={variant.length} onChange={(value) => {
                                const newVariants = [...variant_products];
                                newVariants[i].length = value;
                                setObj({ variant_products: newVariants });
                            }} />

                        </View>

                        <View flex marginL-5>
                            <View row marginV-3 marginT-8>
                                {Label({ label: 'Breadth' })}
                                <TouchableOpacity style={{
                                    marginLeft: 10
                                }} onPress={() => {
                                    const newVariants = [...variant_products];
                                    newVariants[i].breadth = getObj().breadth;
                                    setObj({ variant_products: newVariants });
                                }}>
                                    <Icon name="refresh-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                                </TouchableOpacity>
                            </View>
                            <CustomNumberInput value={variant.breadth} onChange={(value) => {
                                const newVariants = [...variant_products];
                                newVariants[i].breadth = value;
                                setObj({ variant_products: newVariants });
                            }} />
                        </View>
                    </View>

                    <View row spread>
                        <View flex marginR-5>
                            <View row marginV-3 marginT-8>
                                {Label({ label: 'Height' })}
                                <TouchableOpacity style={{
                                    marginLeft: 10
                                }} onPress={() => {
                                    const newVariants = [...variant_products];
                                    newVariants[i].height = getObj().height;
                                    setObj({ variant_products: newVariants });
                                }}>
                                    <Icon name="refresh-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                                </TouchableOpacity>
                            </View>

                            <CustomNumberInput value={variant.height} onChange={(value) => {
                                const newVariants = [...variant_products];
                                newVariants[i].height = value;
                                setObj({ variant_products: newVariants });
                            }} />

                        </View>

                        <View flex marginL-5>
                            <View row marginV-3 marginT-8>
                                {Label({ label: 'Weight' })}
                                <TouchableOpacity style={{
                                    marginLeft: 10
                                }} onPress={() => {
                                    const newVariants = [...variant_products];
                                    newVariants[i].weight = getObj().weight;
                                    setObj({ variant_products: newVariants });
                                }}>
                                    <Icon name="refresh-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                                </TouchableOpacity>
                            </View>
                            <CustomNumberInput value={variant.weight} onChange={(value) => {
                                const newVariants = [...variant_products];
                                newVariants[i].weight = value;
                                setObj({ variant_products: newVariants });
                            }} />
                        </View>
                    </View>

                    <View>
                        <View row marginV-3 marginT-8>
                            {Label({ label: 'Description' })}
                            <TouchableOpacity style={{
                                marginLeft: 10
                            }} onPress={() => {
                                const newVariants = [...variant_products];
                                newVariants[i].description = getObj().description;
                                setObj({ variant_products: newVariants, updateVisiblity: Math.random() });
                            }}>
                                <Icon name="refresh-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                            </TouchableOpacity>
                        </View>

                        <CustomEditorField value={variant.description} onChange={(value) => {
                            const newVariants = [...variant_products];
                            newVariants[i].description = value;
                            setObj({ variant_products: newVariants });
                        }} />
                    </View>

                    <View>
                        <View row marginV-3 marginT-8>
                            {Label({ label: 'Key Benefits' })}
                            <TouchableOpacity style={{
                                marginLeft: 10
                            }} onPress={() => {
                                const newVariants = [...variant_products];
                                newVariants[i].cf___key_benefits_image = getObj().cf___key_benefits_image;
                                setObj({ variant_products: newVariants, updateVisiblity: Math.random() });
                            }}>
                                <Icon name="refresh-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                            </TouchableOpacity>
                        </View>

                        {/* <CustomEditorField value={variant.cf___key_benefits} onChange={(value) => {
                            const newVariants = [...variant_products];
                            newVariants[i].cf___key_benefits = value;
                            setObj({ variant_products: newVariants });
                        }} /> */}

                        <CustomUpload
                            value={variant.cf___key_benefits_image}
                            onChange={(value) => {
                                const newVariants = [...variant_products];
                                newVariants[i].cf___key_benefits_image = value;
                                setObj({ variant_products: newVariants });
                            }}
                            moduleName={moduleName}
                            mimeType={"image/*"}
                        />
                    </View>

                    <View>
                        <View row marginV-3 marginT-8>
                            {Label({ label: 'Ingredients' })}
                            <TouchableOpacity style={{
                                marginLeft: 10
                            }} onPress={() => {
                                const newVariants = [...variant_products];
                                newVariants[i].cf___ingredients_image = getObj().cf___ingredients_image;
                                setObj({ variant_products: newVariants, updateVisiblity: Math.random() });
                            }}>
                                <Icon name="refresh-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                            </TouchableOpacity>
                        </View>

                        {/* <CustomEditorField value={variant.cf___ingredients} onChange={(value) => {
                            const newVariants = [...variant_products];
                            newVariants[i].cf___ingredients = value;
                            setObj({ variant_products: newVariants });
                        }} /> */}

                        <CustomUpload
                            value={variant.cf___ingredients_image}
                            onChange={(value) => {
                                const newVariants = [...variant_products];
                                newVariants[i].cf___ingredients_image = value;
                                setObj({ variant_products: newVariants });
                            }}
                            moduleName={moduleName}
                            mimeType={"image/*"}
                        />
                    </View>

                    <View left>
                        <View row marginV-3 marginT-8>
                            {Label({ label: 'Supplement Facts' })}
                            <TouchableOpacity style={{
                                marginLeft: 10
                            }} onPress={() => {
                                const newVariants = [...variant_products];
                                newVariants[i].cf___supplement_facts = getObj().cf___supplement_facts;
                                setObj({ variant_products: newVariants });
                            }}>
                                <Icon name="refresh-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                            </TouchableOpacity>
                        </View>

                        <CustomUpload
                            value={variant.cf___supplement_facts}
                            onChange={(value) => {
                                const newVariants = [...variant_products];
                                newVariants[i].cf___supplement_facts = value;
                                setObj({ variant_products: newVariants });
                            }}
                            moduleName={moduleName}
                            mimeType={"image/*"}
                        />
                    </View>

                    <View left>
                        <View row marginV-3 marginT-8>
                            {Label({ label: 'Amino Acid Profile' })}
                            <TouchableOpacity style={{
                                marginLeft: 10
                            }} onPress={() => {
                                const newVariants = [...variant_products];
                                newVariants[i].cf___amino_acid_profile = getObj().cf___amino_acid_profile;
                                setObj({ variant_products: newVariants });
                            }}>
                                <Icon name="refresh-outline" fill={'black'} style={{ height: 16, width: 16, marginTop: 2 }} />
                            </TouchableOpacity>
                        </View>

                        <CustomUpload
                            value={variant.cf___amino_acid_profile}
                            onChange={(value) => {
                                const newVariants = [...variant_products];
                                newVariants[i].cf___amino_acid_profile = value;
                                setObj({ variant_products: newVariants });
                            }}
                            moduleName={moduleName}
                            mimeType={"image/*"}
                        />
                    </View>
                </Card>
            })}
        </View>
    }

    const formFields = [
        {
            label: 'Product Type',
            key: 'product_type',
            type: 'select',
            placeholder: 'Select the product type',
            visible: response.module_preferences?.enable_product_type == true,
            width: '25%',
            options: [
                { label: 'Goods', value: 'Goods' },
                { label: 'Service', value: 'Service' },
                { label: 'Subscription', value: 'Subscription' },
                { label: 'Rental', value: 'Rental' },
            ],
            tab: 'General',
            viewField: true,
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const obj = {
                    updateVisiblity: Math.random()
                };
                setObj(obj)
            }
        },
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name of the item',
            visible: true,
            width: '25%',
            tab: 'General',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const sku = value.replace(/[^a-zA-Z0-9-_]/g, '').toUpperCase();
                setObj({ sku: sku });
            }
        },
        {
            label: 'SKU',
            key: 'sku',
            type: 'text',
            placeholder: 'Enter the SKU of the item',
            visible: true,
            width: '25%',
            tab: 'General',
            onChange: (props) => {

            }
        },

        // {
        //     label: 'HSN/SAC',
        //     key: 'hsn_or_sac',
        //     type: 'text',
        //     placeholder: 'Enter the HSN/SAC',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General',
        //     value: response.module_preferences?.default_hsn_or_sac || '',
        // },
        {
            label: 'Unit',
            key: 'unit_id',
            type: 'select',
            placeholder: 'Enter the unit of the item',
            visible: response.module_preferences?.enable_units == true,
            width: '25%',
            tab: 'General',
            options: response.units || [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
                OpenModalCustom({ newModule: "Units", field_key: 'unit_id', params })
            },
            value: response.module_preferences?.default_unit_id || null,
        },
        {
            label: 'Brand',
            key: 'brand_id',
            type: 'select',
            placeholder: 'Enter the brand of the item',
            visible: response.module_preferences?.enable_brands == true,
            width: '25%',
            tab: 'General',
            options: response.brands || [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
                OpenModalCustom({ newModule: "Brands", field_key: 'brand_id', params })
            },
        },
        {
            label: 'Item Category',
            key: 'item_category_id',
            type: 'select',
            placeholder: 'Select the item category',
            visible: true,
            width: '25%',
            options: response.itemCategories || [],
            tab: 'General',
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

                OpenModalCustom({ newModule: "Categories", field_key: 'item_category_id', params })
            },
            value: response.module_preferences?.default_item_category_id || null,
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const category = response.itemCategories.find((el) => el._id == value);
                if (category && category.name.includes("Frame")) {
                    setObj({
                        track_serial_number: true,
                        updateVisiblity: Math.random(),
                    })
                } else {
                    setObj({
                        track_serial_number: false,
                        updateVisiblity: Math.random(),
                    })
                }
            }
        },
        {
            label: 'Tax Group',
            key: 'tax_group_id',
            type: 'select',
            placeholder: 'Select the tax group',
            visible: true,
            width: '25%',
            options: response.taxGroups || [],
            tab: 'General',
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
                OpenModalCustom({ newModule: "TaxGroups", field_key: 'tax_group_id', params })
            },
            value: response.module_preferences?.default_tax_group_id || null,
        },
        {
            label: 'Sales Rate',
            key: 'sales_rate',
            type: 'number',
            placeholder: 'Enter the sales rate',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'MRP Rate',
            key: 'mrp_rate',
            type: 'number',
            placeholder: 'Enter the sales rate',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Sales Account',
            key: 'sales_account_id',
            type: 'select',
            placeholder: 'Select the sales account',
            visible: response.module_preferences?.enable_account_details == true,
            width: '25%',
            options: response.salesAccounts || [],
            tab: 'Accounting Details',
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search, "type": "Sales", "base_type": "Income", "opening_balance": 0 } : {}) };

                OpenModalCustom({ newModule: "Accounts", field_key: 'sales_account_id', params })
            }
        },
        {
            label: 'Purchase Rate',
            key: 'purchase_rate',
            type: 'number',
            placeholder: 'Enter the purchase rate',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Images',
            key: 'image_urls',
            type: 'upload',
            visible: true,
            width: '100%',
            tab: 'General',
            mimeType: "image/*",
            moduleName: moduleName,
            multiple: true,
            value: [],
        },

        {
            label: 'Length',
            key: 'length',
            type: 'number',
            placeholder: 'Enter the length',
            visible: true,
            width: '25%',
            tab: 'General'
        },

        {
            label: 'Breadth',
            key: 'breadth',
            type: 'number',
            placeholder: 'Enter the breadth',
            visible: true,
            width: '25%',
            tab: 'General'
        },

        {
            label: 'Height',
            key: 'height',
            type: 'number',
            placeholder: 'Enter the height',
            visible: true,
            width: '25%',
            tab: 'General'
        },

        {
            label: 'Weight',
            key: 'weight',
            type: 'number',
            placeholder: 'Enter the weight',
            visible: true,
            width: '25%',
            tab: 'General'
        },

        {
            label: 'Description',
            key: 'description',
            type: 'editor',
            placeholder: 'Enter the description of the item',
            visible: response.module_preferences?.enable_description == true,
            width: '100%',
            tab: 'General'
        },

        {
            label: 'Key Benefits',
            key: 'cf___key_benefits_image',
            type: 'upload',
            placeholder: 'Enter the key benefits of the item',
            visible: true,
            width: '100%',
            tab: 'General'
        },

        {
            label: 'Ingredients',
            key: 'cf___ingredients_image',
            type: 'upload',
            placeholder: 'Enter the key benefits of the item',
            visible: true,
            width: '100%',
            tab: 'General'
        },

        {
            label: 'Supplement Facts',
            key: 'cf___supplement_facts',
            type: 'upload',
            visible: true,
            width: '100%',
            tab: 'General',
            mimeType: "image/*",
            moduleName: moduleName,
        },

        {
            label: 'Amino Acid Profile',
            key: 'cf___amino_acid_profile',
            type: 'upload',
            visible: true,
            width: '100%',
            tab: 'General',
            mimeType: "image/*",
            moduleName: moduleName,
        },

        {
            type: 'divider',
            heading: 'Subscriptions Details',
            visible: (connectedObject) => connectedObject.product_type == 'Subscription',
            width: '100%',
            tab: 'General'
        },
        {
            label: 'Duration',
            key: 'subscription_duration',
            type: 'duration',
            duration_options: ["months", "years"],
            placeholder: 'duration',
            visible: (connectedObject) => connectedObject.product_type == 'Subscription',
            width: '25%',
            tab: 'General',
            value: 7776000001,
        },

        {
            label: 'Is Package?',
            key: 'is_package',
            type: 'switch',
            placeholder: 'Enter the is combo product',
            visible: (connectedObject) => connectedObject.product_type == 'Subscription',
            width: '100%',
            tab: 'General',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                if (value == true) {
                    setObj({
                        mapped_items: [],
                        is_combo_product: false,
                        updateVisiblity: Math.random(),
                    })
                } else {
                    setObj({
                        mapped_items: [],
                        updateVisiblity: Math.random(),
                    })
                }
            }
        },
        {
            label: 'Package Items',
            key: 'mapped_items',
            type: 'table',
            placeholder: 'Select the mapped items',
            visible: (connectedObject) => connectedObject.product_type == 'Subscription' && connectedObject.is_package == true,
            width: '100%',
            tab: 'General',
            editable: true,
            columns: [{
                "field": "item_id",
                "label": "Item",
                "editable": true,
                "type": "select",
                "visible": true,
                "order": 1,
                "Header": "Item",
                "accessor": "item_id",
                "placeholder": "Select the item",
                "options": response.other_items || [],
            }, {
                "field": "quantity",
                "label": "Quantity",
                "editable": true,
                "type": "number",
                "visible": true,
                "order": 2,
                "Header": "Quantity",
                "accessor": "quantity",
                "placeholder": "Enter the quantity",
            }],
            actions: true,
            actionFunctions: (row) => ([{
                label: 'Delete',
                key: 'delete',
                fn: ({ data, updateData, row, row_index }) => {
                    let newData = [...data];
                    newData.splice(row_index, 1);
                    updateData(newData);
                }
            }]),
            value: [],
        },
        addButton("Add Package Item", "add_mapped_item", "mapped_items", {}, "General", (connectedObject) => connectedObject.product_type == 'Subscription' && connectedObject.is_package == true),

        {
            label: 'Purchase Account',
            key: 'purchase_account_id',
            type: 'select',
            placeholder: 'Select the purchase account',
            visible: response.module_preferences?.enable_account_details == true,
            width: '25%',
            options: response.purchasesAccounts || [],
            tab: 'Accounting Details',
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search, "type": "Purchase", "base_type": "Expense", "opening_balance": 0 } : {}) };
                OpenModalCustom({ newModule: "Accounts", field_key: 'purchase_account_id', params })
            }
        },
        {
            label: 'Track Inventory',
            key: 'track_inventory',
            type: 'switch',
            placeholder: 'Enter the track inventory',
            visible: false,
            width: '100%',
            tab: 'Inventory Details',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const obj = getObj();
                setObj({ updateVisiblity: Math.random() })
            },
            value: true
        },
        {
            label: 'Inventory Account',
            key: 'inventory_account_id',
            type: 'select',
            placeholder: 'Select the inventory account',
            visible: false,
            width: '25%',
            options: response.inventoryAccounts || [],
            tab: 'Inventory Details',
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search, "type": "Inventory", "base_type": "Asset", "opening_balance": 0 } : {}) };
                OpenModalCustom({ newModule: "Accounts", field_key: 'inventory_account_id', params })
            }
        },
        {
            label: 'Initial Stock',
            key: 'initial_stock',
            type: 'number',
            visible: false,
            placeholder: 'Enter the initial stock',
            // visible: (connectedObject) => connectedObject.track_inventory == true,
            width: '25%',
            tab: 'Inventory Details'
        },
        {
            label: 'Initial Stock Rate',
            key: 'initial_stock_rate',
            type: 'number',
            placeholder: 'Enter the initial stock rate',
            visible: false,
            width: '25%',
            tab: 'Inventory Details'
        },
        // {
        //     label: 'Reorder Level',
        //     key: 'reorder_level',
        //     type: 'number',
        //     placeholder: 'Enter the reorder level',
        //     visible: (connectedObject) => connectedObject.track_inventory == true,
        //     width: '25%',
        //     tab: 'Inventory Details'
        // },
        {
            label: 'Quantity In Hand',
            key: 'quantity_in_hand',
            type: 'number',
            placeholder: 'Enter the quantity in hand',
            visible: false,
            width: '25%',
            tab: 'Inventory Details'
        },
        {
            label: 'Track Serial Number',
            key: 'track_serial_number',
            type: 'switch',
            placeholder: 'Enter the track serial number',
            visible: (connectedObject) => (connectedObject.track_inventory == true && response.module_preferences?.enable_serial_number_tracking == true),
            width: '100%',
            tab: 'Inventory Details',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                if (value == true) {
                    setObj({
                        track_batch_number: false,
                        updateVisiblity: Math.random(),
                    })
                } else {
                    setObj({
                        initital_serial_numbers: [],
                        updateVisiblity: Math.random(),
                    })
                }
            }
        },
        {
            label: 'Serial Numbers',
            key: 'initital_serial_numbers',
            type: 'tags_text_area',
            placeholder: 'Enter the track serial number',
            visible: false,
            width: '100%',
            tab: 'Inventory Details',
            value: []
        },
        // {
        //     label: 'Track Batch Number',
        //     key: 'track_batch_number',
        //     type: 'switch',
        //     placeholder: 'Enter the track batch number',
        //     visible: (connectedObject) => connectedObject.track_inventory == true,
        //     width: '100%',
        //     tab: 'Inventory Details',
        //     onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        //         if (value == true) {
        //             setObj({
        //                 track_serial_number: false,
        //                 updateVisiblity: Math.random(),
        //             })
        //         } else {
        //             setObj({
        //                 initital_batches: [],
        //                 updateVisiblity: Math.random(),
        //             })
        //         }
        //     }
        // },
        {
            label: 'Batch Numbers',
            key: 'initital_batches',
            type: 'table',
            placeholder: 'Enter the batch numbers',
            visible: false,
            width: '100%',
            tab: 'Inventory Details',
            editable: true,
            columns: batchNumbersColumns,
            actions: true,
            actionFunctions: (row) => ([{
                label: 'Delete',
                key: 'delete',
                fn: ({ data, updateData, row, row_index }) => {
                    let newData = [...data];
                    newData.splice(row_index, 1);
                    updateData(newData);
                }
            }]),
            value: []
        },
        // addButton("Add Batch Number", "add_batch_number", "initital_batches", {}, "Inventory Details", (connectedObject) => connectedObject.track_inventory == true && connectedObject.track_batch_number == true),
        {
            label: 'Is Assembeled Using BOM',
            key: 'is_combo_product',
            type: 'switch',
            placeholder: 'Enter the is combo product',
            visible: (connectedObject) => (connectedObject.track_inventory == true && response.module_preferences?.enable_bill_of_materials == true),
            width: '100%',
            tab: 'Inventory Details',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                if (value == true) {
                    setObj({
                        mapped_items: [],
                        is_package: false,
                        updateVisiblity: Math.random(),
                    })
                } else {
                    setObj({
                        mapped_items: [],
                        updateVisiblity: Math.random(),
                    })
                }
            }
        },
        {
            label: 'Mapped Items',
            key: 'mapped_items',
            type: 'table',
            placeholder: 'Select the mapped items',
            visible: (connectedObject) => connectedObject.track_inventory == true && connectedObject.is_combo_product == true,
            width: '100%',
            tab: 'Inventory Details',
            editable: true,
            columns: [{
                "field": "item_id",
                "label": "Item",
                "editable": true,
                "type": "select",
                "visible": true,
                "order": 1,
                "Header": "Item",
                "accessor": "item_id",
                "placeholder": "Select the item",
                "options": response.other_items || [],
            }, {
                "field": "quantity",
                "label": "Quantity",
                "editable": true,
                "type": "number",
                "visible": true,
                "order": 2,
                "Header": "Quantity",
                "accessor": "quantity",
                "placeholder": "Enter the quantity",
            }],
            actions: true,
            actionFunctions: (row) => ([{
                label: 'Delete',
                key: 'delete',
                fn: ({ data, updateData, row, row_index }) => {
                    let newData = [...data];
                    newData.splice(row_index, 1);
                    updateData(newData);
                }
            }]),
            value: [],
        },
        addButton("Add Mapped Item", "add_mapped_item", "mapped_items", {}, "Inventory Details", (connectedObject) => connectedObject.track_inventory == true && connectedObject.is_combo_product == true),
        {
            label: 'Is Package',
            key: 'is_package',
            type: 'switch',
            placeholder: 'Enter the is combo product',
            visible: (connectedObject) => (connectedObject.track_inventory == true && response.module_preferences?.enable_packages == true),
            width: '100%',
            tab: 'Inventory Details',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                if (value == true) {
                    setObj({
                        mapped_items: [],
                        is_combo_product: false,
                        updateVisiblity: Math.random(),
                    })
                } else {
                    setObj({
                        mapped_items: [],
                        updateVisiblity: Math.random(),
                    })
                }
            }
        },
        {
            label: 'Package Items',
            key: 'mapped_items',
            type: 'table',
            placeholder: 'Select the mapped items',
            visible: (connectedObject) => connectedObject.track_inventory == true && connectedObject.is_package == true,
            width: '100%',
            tab: 'Inventory Details',
            editable: true,
            columns: [{
                "field": "item_id",
                "label": "Item",
                "editable": true,
                "type": "select",
                "visible": true,
                "order": 1,
                "Header": "Item",
                "accessor": "item_id",
                "placeholder": "Select the item",
                "options": response.other_items || [],
            }, {
                "field": "quantity",
                "label": "Quantity",
                "editable": true,
                "type": "number",
                "visible": true,
                "order": 2,
                "Header": "Quantity",
                "accessor": "quantity",
                "placeholder": "Enter the quantity",
            }],
            actions: true,
            actionFunctions: (row) => ([{
                label: 'Delete',
                key: 'delete',
                fn: ({ data, updateData, row, row_index }) => {
                    let newData = [...data];
                    newData.splice(row_index, 1);
                    updateData(newData);
                }
            }]),
            value: [],
        },
        addButton("Add Package Item", "add_mapped_item", "mapped_items", {}, "Inventory Details", (connectedObject) => connectedObject.track_inventory == true && connectedObject.is_package == true),

        {
            type: 'custom',
            key: 'custom_attributes',
            visible: response?.module_preferences?.enable_attributes == true && !view,
            width: '100%',
            tab: 'Attributes',
            component: CustomAttributes,
        },

        // {
        //     type: "custom",
        //     key: 'custom_product_options',
        //     visible: (connectedObject) => connectedObject.parent_id ? false : response?.module_preferences?.enable_variant_products == true,
        //     width: '100%',
        //     tab: 'Variants',
        //     component: ProductOptions,
        // },

        // {
        //     label: 'Variant Products',
        //     key: 'variant_products',
        //     type: 'table',
        //     placeholder: 'Select the variant products',
        //     visible: (connectedObject) => connectedObject.parent_id ? false : response?.module_preferences?.enable_attributes == true,
        //     width: '100%',
        //     tab: 'Attributes',
        //     columns: variantProductColumns,
        //     value: []
        // },

        {
            type: 'custom',
            key: 'variant_products_component',
            visible: (connectedObject) => connectedObject.parent_id ? false : response?.module_preferences?.enable_attributes == true,
            width: '100%',
            tab: 'Attributes',
            component: VariantProductsComponent,
        },

    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                tab: 'General 2'
            });
        });
    }

    const defaults = response.defaults;

    if (defaults) {
        for (let key in defaults) {
            const field = formFields.find(field => field.key == key);
            if (field) {
                field.value = defaults[key];
            }
        }
    }

    return formFields;
}

const SidebarStatus = ({ color, text, actionFunctions, item, radius }) => {
    const [containerWidth, setContainerWidth] = React.useState(0);

    return <CustomDropDown
        value={""}
        onChange={(value) => {

        }}
        options={
            [
                ...(actionFunctions && actionFunctions(item).map((el) => {
                    return {
                        label: el.label,
                        value: el.key,
                        onSelect: (value) => {
                            el.fn({ row: item });
                        }
                    }
                }) || [])
            ]
        }
    >
        <View spread backgroundColor={color} style={{ width: 42, height: '100%', borderBottomEndRadius: radius, borderTopEndRadius: radius }}
        >
            <View center>
                <Icon name="more-vertical-outline" fill={'white'} style={{ height: 16, width: 16, marginTop: 10 }} />
            </View>
            <View flex center style={{ position: 'relative', justifyContent: 'flex-end' }}
                onLayout={(e) => {
                    const { height } = e.nativeEvent.layout;
                    setContainerWidth(height - 16);
                }}>
                <Text
                    style={{
                        width: containerWidth,
                        transform: [
                            { rotate: "90deg" },
                            { translateX: -1 * (containerWidth / 2) }
                        ],
                        color: 'white', fontSize: 10, letterSpacing: 1,
                        textAlign: 'right',
                    }}
                >
                    {text}
                </Text>
            </View>
        </View>
    </CustomDropDown>;
};


export const itemMobileCard = (item, index, getFieldValue, onRowClick, actionFunctions) => {
    const radius = 10;

    const stock_required = getFieldValue(item, 'track_inventory') && getFieldValue(item, 'quantity_in_hand') <= getFieldValue(item, 'reorder_level');

    return <Card flex className="hh" style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: radius }}>
        <TouchableOpacity padding-10 flex onPress={() => {
            onRowClick(item);
        }}>
            <View marginB-10 row spread style={{ width: '100%' }}>
                <View center backgroundColor="#FF9595" style={{ width: 60, height: 60, borderRadius: 10 }}>
                    {item.image_urls[0] ?
                        <Image source={{
                            uri: item.image_urls[0]?.url.replace("//uploads", "/uploads")
                        }} style={{ width: 60, height: 60, resizeMode: 'cover', borderRadius: 10 }} /> :
                        <Text style={{ color: 'white', fontSize: 20 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name')[0] : ""}</Text>}
                </View>

                <View flex marginL-10>
                    <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                        {getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}
                    </Text>
                    <View row spread>
                        <View flex >
                            <View row marginB-6>
                                <Text lightCardTitle>Type: </Text>
                                <Text darkCardTitle>{getFieldValue(item, 'product_type')}</Text>
                            </View>
                            <View row>
                                <Text lightCardTitle>HSN or SAC: </Text>
                                <Text darkCardTitle>{getFieldValue(item, 'hsn_or_sac')}</Text>
                            </View>
                        </View>
                        <View flex>
                            <View row marginB-6>
                                <Text lightCardTitle>Sales Price: </Text>
                                <Text darkCardTitle>{getFieldValue(item, 'sales_rate')}</Text>
                            </View>
                            <View row>
                                <Text lightCardTitle>Purchase Price: </Text>
                                <Text darkCardTitle>{getFieldValue(item, 'purchase_rate')}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>

            <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} />

            <View row spread style={{ width: '100%' }}>
                <View row>
                    <Text lightCardTitle>Quantity In Hand: </Text>
                    <Text darkCardTitle>{getFieldValue(item, 'quantity_in_hand')}</Text>
                </View>
                <View row>
                    <Text lightCardTitle>Category: </Text>
                    <Text darkCardTitle>{getFieldValue(item, 'item_category.name')}</Text>
                </View>
            </View>
        </TouchableOpacity>
        <TouchableOpacity>
            <SidebarStatus color={"#397CF2"} text={"Available"} actionFunctions={actionFunctions} item={item} radius={radius} />
        </TouchableOpacity>
    </Card>;
}

const preferencesFields = (response, view = false, moduleName = null, OpenModalCustom) => {
    const formFields = [{
        label: 'Default HSN/SAC',
        key: 'default_hsn_or_sac',
        type: 'text',
        placeholder: 'Enter the default HSN/SAC',
        visible: true,
        width: '25%'
    },
    {
        label: 'Default Unit',
        key: 'default_unit_id',
        type: 'select',
        placeholder: 'Select the default unit',
        visible: true,
        width: '25%',
        options: response.units || [],
    }, {
        label: 'Default Item Category',
        key: 'default_item_category_id',
        type: 'select',
        placeholder: 'Select the default item category',
        visible: true,
        width: '25%',
        options: response.itemCategories || [],
    }, {
        label: 'Default Tax Group',
        key: 'default_tax_group_id',
        type: 'select',
        placeholder: 'Select the default tax group',
        visible: true,
        width: '25%',
        options: response.taxGroups || [],
    }, {
        label: 'Enable Product Type',
        key: 'enable_product_type',
        type: 'checkbox',
        placeholder: 'Enable Product Type',
        visible: true,
        width: '25%',
        value: false
    }, {
        label: 'Enable Unit',
        key: 'enable_units',
        type: 'checkbox',
        placeholder: 'Enable Unit',
        visible: true,
        width: '25%',
        value: false
    }, {
        label: 'Enable Description',
        key: 'enable_description',
        type: 'checkbox',
        placeholder: 'Enable Description',
        visible: true,
        width: '25%',
        value: false
    }, {
        label: 'Enable Variant Products',
        key: 'enable_variant_products',
        type: 'checkbox',
        placeholder: 'Enable Variant Products',
        visible: true,
        width: '25%',
        value: false
    }, {
        label: 'Enable Attributes',
        key: 'enable_attributes',
        type: 'checkbox',
        placeholder: 'Enable Attributes',
        visible: true,
        width: '25%',
        value: false
    }, {
        label: 'Enable Account Details',
        key: 'enable_account_details',
        type: 'checkbox',
        placeholder: 'Enable Account Details',
        visible: true,
        width: '25%',
        value: false
    }, {
        label: 'Enable Brands',
        key: 'enable_brands',
        type: 'checkbox',
        placeholder: 'Enable Brands',
        visible: true,
        width: '25%',
        value: false
    }, {
        label: 'Enable Serial Number Tracking',
        key: 'enable_serial_number_tracking',
        type: 'checkbox',
        placeholder: 'Enable Serial Number Tracking',
        visible: true,
        width: '25%',
        value: false
    }, {
        label: 'Enable Bill of Materials',
        key: 'enable_bill_of_materials',
        type: 'checkbox',
        placeholder: 'Enable Bill of Materials',
        visible: true,
        width: '25%',
        value: false
    }, {
        label: 'Enable Packages',
        key: 'enable_packages',
        type: 'checkbox',
        placeholder: 'Enable Packages',
        visible: true,
        width: '25%',
        value: false
    }];

    return formFields;
}

export const itemMain = {
    fields: itemfields,
    mobileCard: null,
    preferencesFields: preferencesFields,
    tabs: [{
        label: 'All',
        key: 'All',
        value: {}
    }, {
        label: 'Frames',
        key: 'Frames',
        value: {
            "item_category.name": 'Frames',
        }
    }, {
        label: 'Lenses',
        key: 'Lenses',
        value: {
            "item_category.name": 'Lenses',
        }
    }]
}