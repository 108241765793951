import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

// title: { type: String, required: true },
// description: { type: String, required: true },
// featured_images: { type: mongoose.Schema.Types.Array, default: [] },
// seo_title: { type: String, required: false },
// seo_description: { type: String, required: false },
// seo_keywords: [{ type: String, required: false }],

// blog_category_ids: [{ type: mongoose.Schema.Types.ObjectId, ref: 'BlogCategories' }],
// blog_categories: [{ type: mongoose.Schema.Types.Mixed }],

// blog_tag_ids: [{ type: mongoose.Schema.Types.ObjectId, ref: 'BlogTags' }],
// blog_tags: [{ type: mongoose.Schema.Types.Mixed }],

export const blogfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Title',
            key: 'title',
            type: 'text',
            placeholder: 'Title',
            visible: true,
            width: '100%',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const slug = value.replace(/[^a-zA-Z0-9-_]/g, '-').toLowerCase();
                setObj({
                    slug: slug,
                    seo_title: value,
                    updateVisiblity: Math.random()
                });
            }
        },
        {
            label: 'Description',
            key: 'description',
            type: 'editor',
            placeholder: 'description',
            visible: true,
            width: '100%',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                let short_description = value.replace(/<[^>]+>/g, '').substring(0, 255);
                short_description = short_description.replace(/&nbsp;/g, ' ');
                short_description = short_description.replace(/&amp;/g, '&');

                setObj({
                    short_description: short_description,
                    seo_description: short_description,
                    updateVisiblity: Math.random()
                });
            }
        },
        {
            label: 'Featured Images',
            key: 'featured_images',
            type: 'upload',
            mimeType: "image/*",
            placeholder: 'Featured Images',
            visible: true,
            multiple: true,
            moduleName: moduleName,
            width: '100%'
        },
        {
            label: 'Blog Categories',
            key: 'blog_category_ids',
            type: 'select',
            placeholder: 'Blog Categories',
            visible: true,
            multiple: true,
            width: '100%',
            options: response.BlogCategories,
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

                // OpenModalCustom({ newModule: "BlogCategories", field_key: 'blog_category_id', params })
                OpenModalCustom({ newModule: "BlogCategories", field_key: 'blog_category_ids', array: true, params })
            },
        },
        {
            label: 'Blog Tags',
            key: 'blog_tag_ids',
            type: 'select',
            placeholder: 'Blog Tags',
            visible: true,
            multiple: true,
            width: '100%',
            options: response.BlogTags,
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

                OpenModalCustom({ newModule: "BlogTags", field_key: 'blog_tag_ids', array: true, params })
            },
        },
        {
            label: 'Slug',
            key: 'slug',
            type: 'text',
            placeholder: 'Slug',
            visible: true,
            width: '100%'
        },
        {
            label: 'Short Description',
            key: 'short_description',
            type: 'text',
            placeholder: 'Short Description',
            visible: true,
            width: '100%'
        },
        {
            label: 'SEO Title',
            key: 'seo_title',
            type: 'text',
            placeholder: 'SEO Title',
            visible: true,
            width: '100%'
        },
        {
            label: 'SEO Description',
            key: 'seo_description',
            type: 'text',
            placeholder: 'SEO Description',
            visible: true,
            width: '100%'
        },
        {
            label: 'SEO Keywords',
            key: 'seo_keywords',
            type: 'text',
            placeholder: 'SEO Keywords',
            visible: true,
            width: '100%'
        },
    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const blogMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const blogMain = {
    fields: blogfields,
    mobileCard: null
}